<!--
Original author Joshua Bemenderfer
https://alligator.io/vuejs/file-select-component/

Code snippets licensed under MIT, unless otherwise noted.
Content & Graphics © 2019 Alligator.io LLC

Customized by Jani Närvänen / Cheng for Cloudpoint Oy use.
-->
<template>
  <b-row>
    <b-col>
      <div
        class="file-select"
      >
        <span
          v-if="value"
        >
          {{ messages.getLabelSelectedFile() }}: {{value.name}}
        </span>
        <span
          v-else
        >
          {{ messages.getActionSelectFile() }}
        </span>
      </div>
    </b-col>
    <b-col
      cols="12"
    >
      <input
        type="file"
        @change="handleFileChange"
      />
    </b-col>
  </b-row>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'FileUpload',
  props: {
    value: File
  },
  data () {
    return {
      loading: false,
      messages
    }
  },
  computed: {
    fileName () {
      if (this.value) {
        return this.value.name
      }
      return ''
    }
  },
  methods: {
    handleFileChange (e) {
      console.debug('handleFileChange')
      console.debug(e)
      this.file = e.target.files[0]
      this.$emit('file-selected', this.file)
    }
  }
}
</script>

<style scoped>
.file-select > input[type="file"] {
  display: none;
}
</style>

<template>
  <div>
    <b-button
      @click="modal = true"
      :variant="variant"
      class="d-flex align-items-center text-uppercase"
    >
      {{ buttonText }}
      <div
        class="icon-container ml-2"
      >
        <i
          class="icon-cloud-check"
        />
      </div>
    </b-button>
    <b-modal
      v-model="modal"
      hide-header
      hide-header-close
      hide-footer
      centered
      size="lg"
    >
      <h2
        class="mb-5"
      >
        {{ modalTitle }}
      </h2>
      <div
        class="mb-5"
      >
        <p>{{ modalText }}</p>
        <p>{{ messages.getLabelFileProcessingTimeLimit() }}</p>
      </div>
      <file-upload
        @file-selected="fileSelected"
        class="mb-5"
      />
      <b-progress
        value="100"
        animated
        class="mb-5"
        v-show="uploading"
      />
      <div>
        <b-button
          @click="uploadFile"
          :variant="variant"
          class="text-uppercase"
        >
          {{ messages.getActionUpload() }}
          <i
            class="icon-cloud-upload ml-2"
          />
        </b-button>
        <b-button
          class="ml-3 text-uppercase text-white close-button"
          @click="modal = false"
        >
          {{ messages.getActionClose() }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import FileUpload from './FileUpload'

export default {
  name: 'UploadModal',
  components: { FileUpload },
  props: {
    buttonText: {
      required: true,
      type: String
    },
    modalTitle: {
      required: true,
      type: String
    },
    modalText: {
      required: true,
      type: String
    },
    variant: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      modal: false,
      file: null,
      uploading: false,
      messages
    }
  },
  methods: {
    fileSelected (file) {
      console.debug('fileSelected event by emit from child component:')
      console.debug(file)
      this.file = file
    },
    uploadFile () {
      console.debug('uploadFile')
      console.debug(this.file)
      if (this.file) {
        const reader = new FileReader()
        this.uploading = true
        const _self = this
        reader.onload = (function (currFile) {
          return function (evt) {
            if (evt.target.readyState === FileReader.DONE) {
              console.debug('evt.target.result (' + currFile.name + '):')
              console.debug(evt.target.result)
              let base64data = evt.target.result
              base64data = base64data.replace(/data:.*\/*;base64,/gi, '')
              const params = {
                filename: currFile.name,
                base64data
              }
              const closeModal = function () {
                _self.uploading = false
                _self.modal = false
              }
              _self.$emit('upload-file', params, closeModal)
            }
          }
        })(this.file)
        reader.readAsDataURL(this.file)
      }
    }
  }
}
</script>

<style scoped lang="scss">
$icon-font-size: 20px;
.icon-container {
  width: $icon-font-size;
}
.icon-cloud-check {
  font-size: $icon-font-size;
  position: relative;
  left: $icon-font-size / 2;
}
.icon-cloud-upload {
  font-size: $icon-font-size;
}
.close-button {
  background-color: $gray-600;
}
.close-button:hover, .close-button:active, .close-button:focus {
  background-color: darken($gray-600, 5%);
}
</style>

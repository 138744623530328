<template>
  <page-layout>
    <div
      class="h-100 w-100 p-4"
    >
      <b-card
        class="shadow border-0"
      >
        <b-row
        >
          <slot
            name="search"
          />
          <b-col
            cols="12"
            md="5"
            class="d-flex justify-content-around py-2"
          >
            <slot
              name="buttons"
            />
          </b-col>
        </b-row>
        <b-row>
          <slot
            name="cards"
          />
        </b-row>
      </b-card>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '../PageLayout'

export default {
  name: 'PriorityManagerLayout',
  components: { PageLayout }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./DeleteModal.vue?vue&type=template&id=6306e712&scoped=true&"
import script from "./DeleteModal.vue?vue&type=script&lang=js&"
export * from "./DeleteModal.vue?vue&type=script&lang=js&"
import style0 from "./DeleteModal.vue?vue&type=style&index=0&id=6306e712&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6306e712",
  null
  
)

export default component.exports
<template>
  <b-col
    cols="12"
    md="7"
    class="py-2"
  >
    <b-form-tags
      v-model="searchItems"
      @tag-state="setSearch"
      aria-controls="search"
      @input="getPriorities"
      ref="formTag"
      class="px-2"
    >
      <template
        v-slot="{ tags, removeTag, inputAttrs, inputHandlers }"
      >
        <div
          class="d-flex"
        >
          <b-form-tag
            v-for="(tag, key) in tags"
            :key="key"
            @remove="removeTag(tag)"
            pill
            class="badge mx-1"
          >
            <i
              :class="formatIcon(jsonParse(tag))"
            />
            <strong
              class="mx-1"
              v-if="jsonParse(tag, 'type')"
            >
              {{ jsonParse(tag, 'label') }}
            </strong>
            <strong
              v-else
            >
              {{ tag }}
            </strong>
          </b-form-tag>
          <input
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :placeholder="messages.getLabelSearchForAccessPointLimitedByCountries()"
            class="w-100 border-0 p-0 input"
            @focusout="showSuggestionMenu = false"
          ></div>
      </template>
    </b-form-tags>
    <b-collapse
      id="search"
      v-model="showSuggestionMenu"
      class="collapse-position"
    >
      <b-card
        body-class="p-0"
      >
        <b-card-text>
          <div
            v-if="fetchingSuggestions"
            class="p-3"
          >
            {{ messages.getLabelFetchingSuggestions() }}
          </div>
          <b-list-group
            v-else
          >
            <b-list-group-item
              v-for="(suggestion, key) in suggestions"
              :key="key"
              class="border-0 d-flex align-items-center px-3"
              button
              @click.stop="addSuggestion(suggestion)"
            >
              <i
                class="list-icon"
                :class="formatIcon(suggestion)"
              />
              <div
                class="ml-3"
              >
                <div>
                  {{ formatTitle(suggestion) }}
                </div>
                <div
                  class="subtitle"
                >
                  {{ formatSubTitle(suggestion) }}
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-card>
    </b-collapse>
  </b-col>
</template>

<script>
import { messages } from '@/utils/strings'

const SETTINGS = {
  typeDelay: 500
}

export default {
  name: 'SuggestionsSearchBox',
  props: {
    loadingList: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      searchItems: [],
      showSuggestionMenu: false, // Suggestions list is visible, when this is true.
      fetchingSuggestions: false, // A request has been made and we are waiting response from backend.
      search: '',
      requestedSearch: null, // Query string for suggestions request that is currently waiting a response from server.
      messages
    }
  },
  computed: {
    searchQuery () {
      let searchQuery = ''
      for (const searchItem of this.searchItems) {
        if (!searchItem.type) {
          if (searchQuery) {
            searchQuery += ' '
          }
          searchQuery += searchItem
        }
      }
      return searchQuery
    },
    suggestions () {
      return this.$store.state.suggestions
    },
    searchCountries () {
      const countries = []
      for (const searchItem of this.searchItems) {
        if (searchItem.type === 'Country') {
          countries.push(searchItem.value)
        }
      }
      return countries
    }
  },
  methods: {
    setSearch (value) {
      if (value[0]) {
        this.search = value[0]
      } else {
        this.search = ''
      }
    },
    getPriorities () {
      // clear input
      if (this.$refs.formTag) {
        this.$refs.formTag.newTag = ''
      }
      this._storeSearchItems()
      this.$emit('update:loadingList', true)
      this.$store.dispatch('prioritymanager/getPriorities',
        { query: this.searchQuery, countries: this.searchCountries })
        .then((items) => {
          console.debug({ items })
        })
        .finally(() => {
          this.$emit('update:loadingList', false)
          this.showSuggestionMenu = false
        })
    },
    formatIcon (suggestion) {
      let iconName = 'icon-'
      if (suggestion.type) {
        if (suggestion.type === 'Country') {
          iconName += 'globe'
        } else {
          iconName += 'tag'
        }
      } else {
        iconName += 'edit'
      }
      return iconName
    },
    formatTitle (suggestion) {
      // TODO: Return correct title
      return suggestion.label
    },
    formatSubTitle (suggestion) {
      // TODO: Return correct subtitle
      return suggestion.countryCode
    },
    jsonParse (value, prop = false) {
      // parse string to object
      try {
        const object = JSON.parse(value)
        if (prop) {
          return object[prop]
        }
        return object
      } catch {
        return value
      }
    },
    addSuggestion (suggestion) {
      console.debug({ suggestion })
      // this.searchItems = [ ...this.searchItems, suggestion.value ]
      // convert object to string because b-form-tag accept only strings not objects
      suggestion = JSON.stringify(suggestion)
      this.searchItems = [...this.searchItems, suggestion]
      this.search = '' // Clear free text search when suggestion has been selected
      this.getPriorities()
    },
    _storeSearchItems () {
      localStorage.setItem('searchItems_AP_PriorityManager', JSON.stringify(this.searchItems))
    },
    getSuggestions ({ search }) {
      if (!search || search === 'null') {
        console.debug('Empty suggestions search. Nop.')
        return
      }
      this.fetchingSuggestions = true
      this.dispatchSuggestionsRequest({ search })
        .finally(() => {
          console.debug('finally triggered')
          this.showSuggestionMenu = true
          this.fetchingSuggestions = false
        })
    },
    dispatchSuggestionsRequest ({ search }) {
      return new Promise((resolve) => {
        console.debug(`Getting suggestions for ${search}`)
        this.requestedSearch = '' + search
        return this.$store.dispatch('getUAPSuggestionCountries', { query: this.requestedSearch })
          .then((resp) => {
            this.showSuggestionMenu = false
            if (this.search !== this.requestedSearch) {
              console.debug('Search term changed while making request')
              return this.$nextTick()
                .then(() => {
                  console.debug('nextTick')
                  if (search) {
                    this.getSuggestions({ search: '' + this.search })
                  }
                  resolve(true)
                })
            }
            console.debug('Resolving first dispatch')
            resolve(true)
          })
      })
    },
    loadSearchHistory () {
      const searchItems = this._loadStoredSearchItems()
      if (searchItems) {
        this.searchItems = searchItems
        this.getPriorities()
      } else {
        this.getPriorities()
      }
    },
    _loadStoredSearchItems () {
      const storage = localStorage.getItem('searchItems_AP_PriorityManager')
      if (storage) {
        return JSON.parse(storage)
      } else {
        return null
      }
    }
  },
  created () {
    // console.debug('AccessPointPriorityManager.vue created')
    if (this.$store.state.profile.licensed) {
      this.loadSearchHistory()
    }
  },
  watch: {
    search: function (newValue) {
      if (!newValue) {
        console.debug('Empty search. Nop.')
        return
      }
      console.debug(`search model changed to ${newValue} (${newValue.length})`)
      if (newValue && newValue.length >= 2) {
        if (this.searchDelayHandler) {
          console.debug('Clearing search delay handler')
          clearTimeout(this.searchDelayHandler)
          this.searchDelayHandler = null
        }
        console.debug('Setting timeout for search')
        this.searchDelayHandler = setTimeout((this.getSuggestions),
          SETTINGS.typeDelay, { search: '' + this.search })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.collapse-position {
  z-index: 2000;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 15px 0 15px;
}
.input:focus {
  outline: none;
}
.badge {
  font-size: initial;
}
.list-icon {
  font-size: 20px;
}
.subtitle {
  color: $gray-500;
}
</style>

<template>
  <priority-manager-layout>
    <template
      v-slot:search
    >
      <suggestions-search-box
        ref="suggestionSearchBox"
        :loading-list.sync="loadingList"
      />
    </template>
    <template
      v-slot:buttons
    >
      <upload-modal
        :button-text="messages.getActionUploadToAddPriorities()"
        :modal-title="messages.getPageModalUploadShipToPartyUapPrioritiesTitle()"
        :modal-text="messages.getPageModalUploadShipToPartyUapPrioritiesText()"
        variant="primary"
        v-if="shouldDisplayUploadAddButton"
        @upload-file="_uploadFile"
      />
      <upload-modal
        :button-text="messages.getActionUploadToDeletePriorities()"
        :modal-title="messages.getPageModalUploadShipToPartyNumbersTitle()"
        :modal-text="messages.getPageModalUploadShipToPartyNumbersText()"

        variant="danger"
        v-if="shouldDisplayUploadDeleteButton"
        @upload-file="_uploadDisableFile"
      />
    </template>
    <template
      v-slot:cards
    >
      <b-col
        cols="12"
        class="pt-3"
        v-show="loadingList"
      >
        <b-progress
          value="100"
          animated
        />
      </b-col>
      <access-point-priority-card
        v-for="(ap, index) in sortedUapInfos"
        :key="ap.shipToPartyCode"
        @disable-detection="removedDisabledUAPInfos"
        v-model="sortedUapInfos[index]"
      />
    </template>
  </priority-manager-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import {
  PM_READ,
  PM_UPLOAD_ADD,
  PM_UPLOAD_DISABLE,
  PM_WRITE,
  UM_PM_GRANT_READ,
  UM_PM_GRANT_WRITE,
  SUPER_MANAGER,
  SUPER_MANAGER_READ_ONLY
} from '@/constants/permissions'
import AccessPointPriorityCard from '../components/PriorityManager/AccessPointPriorityCard/AccessPointPriorityCard'
import SuggestionsSearchBox from '../components/PriorityManager/SuggestionsSearchBox'
import UploadModal from '../components/PriorityManager/UploadModal'
import axios from 'axios'
import { wrapAxiosError } from '@/store/helpers'
import PriorityManagerLayout from '../components/PriorityManager/PriorityManagerLayout'

export default {
  name: 'PriorityManager',
  components: { PriorityManagerLayout, UploadModal, SuggestionsSearchBox, AccessPointPriorityCard },
  data () {
    return {
      ready: false,
      loadingList: false,
      shouldDisplayUploadAddButton: false,
      shouldDisplayUploadDeleteButton: false,
      messages
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    uapInfos () {
      return this.$store.state.prioritymanager.uapInfos
    },
    sortedUapInfos () {
      const firstElements = []
      const _self = this
      const filtered = this.uapInfos.filter(function (value, index, arr) {
        if (_self.profile.managedShipToPartyNumbers.includes(value.shipToPartyCode)) {
          firstElements.push(value)
          return false
        }
        return true
      })
      return firstElements.concat(filtered)
    }
  },
  watch: {
    '$store.state.profile.permissions': {
      deep: true,
      handler () {
        this.checkPermissions()
      }
    }
  },
  mounted () {
    this.checkPermissions()
  },
  methods: {
    checkPermissions () {
      if (
        !this.$store.getters.hasCurrentUserSomeOfPermissions([
          SUPER_MANAGER, SUPER_MANAGER_READ_ONLY, PM_READ, PM_WRITE, PM_UPLOAD_ADD,
          PM_UPLOAD_DISABLE, UM_PM_GRANT_WRITE, UM_PM_GRANT_READ
        ])
      ) {
        this.$router.push('/status-monitor')
      }
      this.shouldDisplayUploadAddButton = this.$store.getters.hasCurrentUserSomeOfPermissions([SUPER_MANAGER, PM_UPLOAD_ADD])
      this.shouldDisplayUploadDeleteButton = this.$store.getters.hasCurrentUserSomeOfPermissions([SUPER_MANAGER, PM_UPLOAD_DISABLE])
    },
    removedDisabledUAPInfos (shipToParty) {
      this.$store.state.prioritymanager.uapInfos = this.uapInfos.filter(function (value, index, arr) {
        return value.shipToPartyCode !== shipToParty
      })
    },
    _uploadFile ({ base64data, filename }, closeModal) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/tracking/uap/priorities/upload`
        const sdata = {
          base64data,
          contentType: 'text/csv',
          filename
        }
        axios.put(url, sdata)
          .then((resp) => {
            console.debug({ resp })
            const data = resp.data
            const value = {
              shipToPartyCode: data.shipToPartyCode
            }
            value.priorities = data.uapinfos
            closeModal()
            this.$refs.suggestionSearchBox.getPriorities()
            resolve(value)
          })
          .catch((err) => wrapAxiosError(err, reject))
      })
    },
    _uploadDisableFile ({ base64data, filename }, closeModal) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/tracking/uap/priorities/upload/disabled`
        const sdata = {
          base64data,
          filename
        }
        axios.put(url, sdata)
          .then((resp) => {
            console.debug({ resp })
            const data = resp.data
            closeModal()
            this.$refs.suggestionSearchBox.getPriorities()
            resolve(data)
          })
          .catch((err) => wrapAxiosError(err, reject))
      })
    }
  }
}
</script>

<style scoped>
</style>

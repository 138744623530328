<template>
  <b-col
    cols="12"
    lg="6"
    class="p-0"
  >
    <b-card
      class="m-2"
      body-class="p-2"
    >
      <b-progress
        value="100"
        animated
        v-show="showLoading"
      />
      <table
        class="ap-table"
      >
        <thead>
          <tr>
            <th
              class="party-number"
            >
              <div
                :id="value.shipToPartyCode"
                class="text-center"
              >
                {{ value.shipToPartyCode }}
              </div>
              <b-tooltip
                :target="value.shipToPartyCode"
                placement="bottom"
              >
                {{ messages.getFieldShipToPartyNumber() }}
              </b-tooltip>
            </th>
            <th
              class="priority"
            >
              <div
                :id="'priority-' + value.shipToPartyCode"
              >
                {{ messages.getFieldPriority() }}
              </div>
              <b-popover
                :target="'priority-' + value.shipToPartyCode"
                triggers=""
                placement="bottom"
                :show.sync="showPriorityMenu"
              >
                <b-list-group
                  flush
                >
                  <b-list-group-item
                    button
                    class="border-0"
                    @click="setTopPriority(selectedIndex)"
                  >
                    {{ messages.getActionSetTop() }}
                  </b-list-group-item>
                  <b-list-group-item
                    button
                    class="border-0"
                    @click="setSecondPriority(selectedIndex)"
                  >
                    {{ messages.getActionSetSecond() }}
                  </b-list-group-item>
                  <b-list-group-item
                    button
                    class="border-0"
                    @click="setLowestPriority(selectedIndex)"
                  >
                    {{ messages.getActionSetBottom() }}
                  </b-list-group-item>
                </b-list-group>
              </b-popover>
            </th>
            <th
              class="address"
            >
              <b-row>
                <b-col
                  cols="9"
                  sm="10"
                  align-self="center"
                >
                  {{ messages.getFieldAccessPointAddress() }}
                </b-col>
                <b-col
                  cols="3"
                  sm="2"
                  v-if="doesUserHaveWriteAccess"
                >
                  <delete-modal
                    :ship-to-party-code="value.shipToPartyCode"
                    :show-loading.sync="showLoading"
                    @disable-detection="$emit('disable-detection', $event)"
                  />
                </b-col>
              </b-row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(uap, index) in value.priorities"
            :key="index"
            @click="adjustPriority(index)"
            class="text-center no-outline"
            tabindex="0"
            @focusout="showPriorityMenu = false"
          >
            <td>{{ uap.id }}</td>
            <td>{{ index + 1 }}</td>
            <td
              class="address"
            >
              {{ formatAddress(uap) }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </b-col>
</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'
import { wrapAxiosError } from '@/store/helpers'
import { PM_WRITE, SUPER_MANAGER, UM_PM_GRANT_WRITE } from '@/constants/permissions'
import DeleteModal from './DeleteModal'

export default {
  name: 'AccessPointPriorityCard',
  components: { DeleteModal },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
        }
      }
    }
  },
  data () {
    return {
      showPriorityMenu: false,
      showLoading: false,
      adjustingIndex: null,
      messages
    }
  },
  computed: {
    doesUserHaveWriteAccess () {
      if (this.$store.getters.hasCurrentUserSomeOfPermissions([SUPER_MANAGER, UM_PM_GRANT_WRITE])) {
        return true
      }
      return (
        this.$store.getters.hasCurrentUserPermission(PM_WRITE) &&
        this.$store.state.profile.managedShipToPartyNumbers.includes(this.value.shipToPartyCode)
      )
    }
  },
  methods: {
    adjustPriority (index) {
      console.debug('index ' + index)
      console.debug({ value: this.value })
      if (!this.doesUserHaveWriteAccess) {
        return
      }
      this.showPriorityMenu = true
      this.selectedIndex = index
      this.showLoading = false
    },
    async setTopPriority (selectedIndex) {
      if (selectedIndex === 0) {
        return
      }
      const selectedUap = this.value.priorities.splice(selectedIndex, 1)[0]
      console.debug({ selectedUap, aps: this.value.priorities })
      const newPriorityArray = this.value.priorities
      newPriorityArray.splice(0, 0, selectedUap)
      this.showLoading = true
      const resp = await this._updatePriorities({ shiptoparty: this.value.shipToPartyCode, newPriorityArray })
      this.showLoading = false
      console.debug(resp)
      this.value.priorities = resp.priorities
      console.debug('Changed priority')
    },
    async setSecondPriority (selectedIndex) {
      if (selectedIndex === 1) {
        return
      }
      const selectedUap = this.value.priorities.splice(selectedIndex, 1)[0]
      const newPriorityArray = this.value.priorities
      newPriorityArray.splice(1, 0, selectedUap)
      this.showLoading = true
      const resp = await this._updatePriorities({ shiptoparty: this.value.shipToPartyCode, newPriorityArray })
      this.showLoading = false
      console.debug(resp)
      this.value.priorities = resp.priorities
    },
    async setLowestPriority (selectedIndex) {
      const lastIndex = this.value.priorities.length - 1
      if (selectedIndex === lastIndex) {
        return
      }
      const selectedUap = this.value.priorities.splice(selectedIndex, 1)[0]
      const newPriorityArray = this.value.priorities
      newPriorityArray.push(selectedUap)
      this.showLoading = true
      const resp = await this._updatePriorities({ shiptoparty: this.value.shipToPartyCode, newPriorityArray })
      this.showLoading = false
      console.debug(resp)
      this.value.priorities = resp.priorities
    },
    formatAddress (uap) {
      // TODO: Return proper address
      // FIXME: Return escaped html
      // return '<span class="">'
      return `${uap.street}, ${uap.zipcode} ${uap.city}, ${uap.countryCode}`.toLowerCase()
    },
    copyUap (uap) {
      // This might be obsolete
      const tempUap = JSON.parse(JSON.stringify(uap))
      return {
        name: tempUap.name,
        street: tempUap.street,
        city: tempUap.city,
        countryCode: tempUap.countryCode,
        id: tempUap.id,
        zipcode: tempUap.zipcode
      }
    },
    _updatePriorities ({ shiptoparty, newPriorityArray }) {
      return new Promise((resolve, reject) => {
        if (newPriorityArray.length !== 3) {
          return
        }
        const uapId1 = newPriorityArray[0].id
        const uapId2 = newPriorityArray[1].id
        const uapId3 = newPriorityArray[2].id
        const url =
          `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/tracking/uap/priorities/${shiptoparty}`
        const sdata = {
          uapId1,
          uapId2,
          uapId3
        }
        for (const uapId of Object.values(sdata)) {
          if (!uapId) {
            console.error('One of UAP IDs is missing. Can not update priorities.')
            return
          }
        }
        axios.put(url, sdata)
          .then((resp) => {
            console.debug({ resp })
            const data = resp.data.items[0]
            const value = {
              shipToPartyCode: data.shipToPartyCode
            }
            value.priorities = data.uapinfos
            resolve(value)
          })
          // we use resolve instead of reject here, as we really handle
          // the error in wrapAxiosError
          .catch((err) => wrapAxiosError(err, resolve))
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ap-table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      color: $primary;
      padding: 2px 0;
    }
    th.party-number {
      width: 20%;
    }
    th.priority {
      width: 2%;
      padding: 0 16px;
    }
    th.address {
      width: 100%;
      text-align: left;
      padding: 0;
    }
  }
  tbody {
    tr:hover {
      cursor: pointer;
      background-color: #EEE;
    }
    td {
      padding: 2px 0;
    }
    td.address {
      text-align: left;
      text-transform: capitalize;
    }
  }
}
.pointer {
  cursor: pointer;
}
.no-outline:focus {
  outline: none;
  background-color: #EEE;
}
</style>

<template>
  <div>
    <b-button
      variant="link"
      class="text-decoration-none float-right p-0"
      @click="showDisableConfirmation = true"
    >
      <i
        :id="'disable-' + shipToPartyCode"
        class="icon-circle-minus text-danger"
      />
      <b-tooltip
        :target="'disable-' + shipToPartyCode"
        placement="bottom"
      >
        {{ messages.getActionDelete() }}
      </b-tooltip>
    </b-button>
    <b-modal
      v-model="showDisableConfirmation"
      hide-footer
      hide-header
      centered
    >
      <h3
        class="text-center mb-4"
      >
        {{ messages.getLabelDeleteConfirmation() }}
      </h3>
      <b-row>
        <b-col
          class="d-flex justify-content-around"
        >
          <b-button
            @click="disableUAPPriority"
            variant="danger"
            class="text-uppercase"
          >
            {{ messages.getActionDelete() }}
          </b-button>
          <b-button
            @click="showDisableConfirmation = false"
            class="close-button text-uppercase text-white"
          >
            {{ messages.getActionClose() }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { messages } from '@/utils/strings'

export default {
  name: 'DeleteModal',
  props: {
    shipToPartyCode: {
      type: String,
      required: true
    },
    showLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showDisableConfirmation: false,
      messages
    }
  },
  methods: {
    disableUAPPriority () {
      this.showDisableConfirmation = false
      const shiptoparty = this.shipToPartyCode
      this.$emit('update:showLoading', true)
      const _self = this
      const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/tracking/uap/priorities/${shiptoparty}`
      axios.delete(url)
        .then((resp) => {
          console.debug({ resp })
          const data = resp.data
          _self.$emit('update:showLoading', false)
          if (data.disable) {
            _self.$emit('disable-detection', data.shipToPartyCode)
          }
        })
        .catch((error) => {
          this.$store.commit('setErrorMessage', error.response.data.message)
          _self.$emit('update:showLoading', false)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.icon-circle-minus {
  font-size: 18px;
  color: $primary;
}
.close-button {
  background-color: $gray-600;
}
.close-button:hover, .close-button:active, .close-button:focus {
  background-color: darken($gray-600, 5%);
}
</style>
